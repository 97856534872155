const result = {
  city: 'New York City',
  condition: 'Sunny',
  precipitation: '50%',
  temperature: 31,
  highestTemperature: 32,
  lowestTemperature: 25
};

export default result;
